var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-12",attrs:{"color":"grey lighten-1","elevation":"0"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"my-2",attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.routes,"outlined":"","readonly":_vm.mode==3,"item-text":"name","item-value":"id","label":"Route","persistent-hint":"","return-object":"","required":"","rules":_vm.requiredRules,"hint":_vm.selectedRoute != null
                ? (_vm.selectedRoute.is_morning
                  ? 'Morning route'
                  : 'Afternoon route')
                : _vm.trip.route != null ? (_vm.trip.route.is_morning ? 'Morning route' : 'Afternoon route') : ''},on:{"change":_vm.routeSelected},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
                var item = ref.item;
return [(item)?_c('v-list-item',_vm._g(_vm._b({key:item.id},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" "),_c('v-chip',{attrs:{"dense":"","color":_vm.getTimeColor(item.is_morning),"dark":""}},[_vm._v(" "+_vm._s(item.is_morning ? 'Morning' : 'Afternoon')+" ")])],1),_c('v-list-item-subtitle')],1)],1):_vm._e()]}}]),model:{value:(_vm.trip.route),callback:function ($$v) {_vm.$set(_vm.trip, "route", $$v)},expression:"trip.route"}})],1),_c('v-col',{staticClass:"my-2",attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{ref:"effective_date",attrs:{"return-value":_vm.trip.effective_date,"disabled":_vm.mode==3,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.trip, "effective_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.trip, "effective_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Effective date","dense":"","outlined":"","readonly":"","required":"","rules":_vm.requiredRules},model:{value:(_vm.trip.effective_date),callback:function ($$v) {_vm.$set(_vm.trip, "effective_date", $$v)},expression:"trip.effective_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.trip.effective_date),callback:function ($$v) {_vm.$set(_vm.trip, "effective_date", $$v)},expression:"trip.effective_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.effective_date.save(_vm.trip.effective_date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"my-2",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","readonly":_vm.mode==3,"label":"Repeated every (days)","placeholder":"Enter repetition period (days)","hint":"0 means no repetition","persistent-hint":"","required":"","rules":_vm.repeatRules},model:{value:(_vm.trip.repetition_period),callback:function ($$v) {_vm.$set(_vm.trip, "repetition_period", $$v)},expression:"trip.repetition_period"}})],1),_c('v-col',{staticClass:"my-2",attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{ref:"first_stop_time",attrs:{"return-value":_vm.trip.first_stop_time,"disabled":_vm.mode==3,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.trip, "first_stop_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.trip, "first_stop_time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"Arrival time at first stop","readonly":"","required":"","rules":_vm.requiredRules},model:{value:(_vm.trip.first_stop_time),callback:function ($$v) {_vm.$set(_vm.trip, "first_stop_time", $$v)},expression:"trip.first_stop_time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[(_vm.modal2)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.trip.first_stop_time),callback:function ($$v) {_vm.$set(_vm.trip, "first_stop_time", $$v)},expression:"trip.first_stop_time"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.first_stop_time.save(_vm.trip.first_stop_time)}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1),_c('v-col',{staticClass:"my-2",attrs:{"cols":"12","md":"4"}},[(_vm.mode==1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"type":"number","outlined":"","dense":"","label":"Stop-to-stop time (minutes)","placeholder":"Enter average stop-to-stop time (min)","hint":"Can be changed later","persistent-hint":"","required":"","rules":_vm.timeRules},model:{value:(_vm.trip.stop_to_stop_avg_time),callback:function ($$v) {_vm.$set(_vm.trip, "stop_to_stop_avg_time", $$v)},expression:"trip.stop_to_stop_avg_time"}},'v-text-field',attrs,false),on))]}}],null,false,463723200)},[_c('span',[_vm._v("This has no effect if you duplicate a previous trip with the same route")])]):_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","readonly":_vm.mode==3,"label":"Stop-to-stop time (minutes)","placeholder":"Enter average stop-to-stop time (min)","hint":"Can be changed later","persistent-hint":"","required":"","rules":_vm.timeRules},model:{value:(_vm.trip.stop_to_stop_avg_time),callback:function ($$v) {_vm.$set(_vm.trip, "stop_to_stop_avg_time", $$v)},expression:"trip.stop_to_stop_avg_time"}})],1)],1)],1)],1),(_vm.mode!=3)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.next}},[_vm._v(" Continue ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }